import $ from 'jquery';
import whatInput from 'what-input';

window.$ = $;

import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

$(document).ready(function(){
 $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $('.scrollup').fadeIn();
    } else {
      $('.scrollup').fadeOut();
    }
  });

  $('.scrollup').click(function () {
    $("html, body").animate({
      scrollTop: 0
    }, 600);
    return false;
  });

  $("[data-menu-underline-from-center] a").addClass("underline-from-center");
  
  $('.trigger').click(function() {
      var $this = $(this),
      notThis = $this.hasClass('open'),
      thisNav = $this.attr("rel");
      //close if you click another menu trigger
      if (!notThis) {
    	 $('.triggered-area, .trigger').removeClass('open');
    	 if ( $( '.trigger' ).hasClass( "block-scroll" ) ){
    		$('html').removeClass('disable-scroll');
    		$('#overlay-mobile').removeClass('visible');
    	 }
      }
      //open the nav
      $this.toggleClass('open');
      $("#"+thisNav).toggleClass('open');
      if ( $( this ).hasClass( "block-scroll" ) ){
    	 $('html').toggleClass('disable-scroll');
    	 $('#overlay-mobile').toggleClass('visible');
      }
    });
    //close if you click on anything but this nav item or a trigger
    $(document).on('click', function(event) {
      if (!$(event.target).closest('.triggered-area, .trigger').length) {
    	 $('.triggered-area, .trigger').removeClass('open');
    	 if ( $( '.trigger' ).hasClass( "block-scroll" ) ){
    		$('html').removeClass('disable-scroll');
    		$('#overlay-mobile').removeClass('visible');
    	 }
      }
    });
  });